import React from 'react';

import get from 'lodash/get';

import { Hero } from 'components/organisms';
import { ModuleRenderer } from 'utils/module-renderer';
import { PageDataLoader } from 'utils/page-data-loader';
import { VerifyPageContext } from 'utils/verify-page-context';
import { QUERY_TYPES } from 'constants/query';

import { getFilteredComponents } from '../../../utils/get-filtered-components';

/**
 * Renders the Landing Page template.
 * @param {Object} pageData The Contentful data object.
 */
const LandingPage = ({ pageData }) => {
  const {
    modulesCollection: { items: components },
    __typename: pageContext,
    hero,
  } = pageData;
  const verifiedContext = VerifyPageContext(pageContext);
  const filteredComponents = getFilteredComponents(components);
  return (
    <>
      <article>
        {/* Hero */}
        {hero && (
          <Hero
            key={get(hero, 'sys.id')}
            data={pageData}
            pageContext={verifiedContext}
          />
        )}
        {/* Modules */}
        <section>
          <ModuleRenderer
            components={filteredComponents}
            pageContext={verifiedContext}
          />
        </section>
      </article>
    </>
  );
};

export default PageDataLoader(LandingPage, {
  pageSlug: false,
  queryType: QUERY_TYPES.PAGE_LANDING_QUERY,
});
